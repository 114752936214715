/**************************************

common.scss

***************************************/

*{
	box-sizing: border-box;
}


/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　　　　汎用class
----------------------------------------------------------*/

.br_pc{
	display: block;
	@include media($mobileSize){
		display: none;
	}
}

.br_sp{
	display: none;
	@include media($mobileSize){
		display: inherit;
	}
}

.container{
		width: 100%;
		height: auto;
		margin: 0 auto;
}

.main{
	margin-bottom: 70px;
	@include media($mobileSize){
		margin-bottom: 50px;
	}
}

.outer{
	width: 100%;
	max-width: 1280px;
	min-width: 1000px;
	margin: 0 auto;
	@include media($mobileSize){
		min-width: inherit;
	}
}

.inner{
	width: 960px;
	margin: 0 auto;
	@include media($mobileSize){
		width: 87.5%;
		img{
			width: 100%;
		}
	}
}
.nav__current{
	color: $fontColorMain;
}


.telLinks {pointer-events: none;
	@include media($mobileSize) {
		 pointer-events: inherit;
	}	
}



/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　　　　header
----------------------------------------------------------*/
header{
	width: 100%;
	/*min-width: 1280px;*/
	min-width: 1000px;
	height: 80px;
	background:url(../img/common/common_fotter_bg.jpg) repeat-x center center;
	
	@include media($mobileSize){
		height: 70px;
		min-width:inherit;	
	}
}
	
.header_inner{
	width: 960px;
	height: auto;
	margin: 0 auto;
	@include clearfix();
	
	@include media($mobileSize){
		/*width: 87.5%;*/
		width: 95%;
	}
}
	
.header_inner .left_box{
	/*width: 290px;*/
	width: 34.5%;
	float: left;
	
	.logo_box{
		width: 49.84%;
		display: inline-block;
		margin-top: 1%;
		@include media($mobileSize){
			width: 27.1%;
		}
		img{
			width: 100%;
		}
	}
	p.header__conpanyName{
		@include fontsize(27);
		display: inline-block;
		vertical-align: text-top;
		margin-top: 32px;
		color: $fontColorMain;
		span {
			@include fontsize(18);
		}
		a {
			font-weight: bold;
		}
	}
	@include media($mobileSize){
		width: 100%;	
		p.header__conpanyName{
			@include fontsize(20);
			margin-top: 35px;
			span {
				@include fontsize(16);
			}
		}
	}
}

.header_inner .right_box{
	width: 62.55%;
	height: 80px;
	float: right;
	letter-spacing: -.4em;
	@include clearfix();

	@include media($mobileSize){
		display: none;
	}
	
	.tel_box{
		display: inline-block;
		letter-spacing: normal;
		margin-right: 13px;
		p {
			span a {
				font-weight: bold;
				color: $fontColorSub;
			}
		}
	}
	
	.hours_box{
		@include fontsize(13);
		display: inline-block;
		letter-spacing: normal;
		margin-right: 16px;
		p{
			color: $fontColorSub;
		}
	}
	
	.top_box{
		width: 98px;
		display: inline-block;
		letter-spacing: normal;
		margin-left: auto;
		margin-top: 6px;
	   p{
				@include fontsize(13);
				width: 100%;
		   a{
				width: 100%;
				background-color: $keyColor;
				text-align: center;
				line-height: 27px; 
				display: block;
				color: $fontColorSub;
				font-weight: bold;
		   }
		}
	}

	.bottom_box{
		width: 100%;
		height: 50%;
		text-align: right;
		
		ul{
			letter-spacing: -.4em;
			li{
				display: inline-block;
				letter-spacing: normal;
				color: $fontColorMain;
				@include fontsize(14);
				margin: 15px 0 0;
				margin-left: 40px;
				
				&:first-of-type{
					margin-left: 0;
				}
				
				a{
					color: $fontColorMain;
					&:hover{
						color: $fontColor03;
					}
				}
				.nav__current{
					color: $fontColorHeading2;
				}
			}
		}
	}

}

/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　navi_btn　　　
----------------------------------------------------------*/
	
 #sp_nav_box{
	display:none;

	 @include media($mobileSize){
		 display:block;

		.noscroll {
		  overflow: hidden;
		  width: 100%;
		  height: 100%;
	  }

		#sp_nav_btn{
			display: block;
			width: 70px;
			height: 70px;
			position: fixed;
			top: 0px;
			right: 0px;
			z-index: 998;
		}

		#sp_nav_btn img{
			width: 100%;
			height: auto;
		}
		 
/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　navi_inner　　　
----------------------------------------------------------*/
		 

		#sp_cover{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
			z-index: 999;
			display: none;
		}

		#sp_nav {
			width: 220px;
			height: 100%;
			background-color: #fff;
			position: fixed;
			top: 0;
			right: -220px;
			overflow-y: scroll;
			z-index: 1000;
			display: none;
		}

		 #sp_nav .nav_close_wrap{
			text-align: right;
		 }

		  #sp_nav .nav_close_wrap .nav_close{
			width: 70px;
			height: 70px;
			cursor: pointer;
			display: inline-block;
		  }

		  #sp_nav .nav_close_wrap .nav_close img{
			width: 100%;
			height: auto;
		  }

		  #sp_nav .sp_nav_wrapper li a{
			padding: 15px 0 15px 20px;
			display: block;
			color: #333333;
			font-size: 14px;
			font-weight: bold;
			border-bottom: 1px solid #eee;
			text-decoration: none;
		  } 
		 }

}



/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　　　navi_inner
----------------------------------------------------------*/


/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　　　　page top btn
----------------------------------------------------------*/	

.top_btn_conts{
	width: 100%;
	/*min-width: 1280px;*/
	min-width: 1000px;
	height: auto;

	@include media($mobileSize){
		min-width:inherit;
	}
}


.top_btn{
	width: 960px;
	height: auto;
	margin: 0 auto;
	
	@include media($mobileSize){
		width: 87.5%;
	}
	
	.goto_top{
		text-align: right;
	}

	img{
		width: 100%;
		max-width: 72px;
		height: auto;
	}
}

/* ---------------------------------------------------------
         　　　　　　　　　　　　　　　　　　　footer
----------------------------------------------------------*/

footer{
	width: 100%;
	/*min-width: 1280px;*/
	min-width: 1000px;
	height: auto;
	background-color: #f2f2f2;
	margin: -40px auto 0;
	padding: 30px 0;
	
	@include media($mobileSize){
		min-width:inherit;
	}
}
	
.footer_inner{
	width: 960px;
	height: auto;
	margin: 0 auto;
	
	@include media($mobileSize){
		width: 87.5%;
	}
}

.footer_inner .box_inner{
	@include clearfix();
}

.footer_inner .left_box{
	float: left;
	width: 54%;

	@include media($mobileSize){
	float: none;
	width: 100%;
	margin: 5% 0; 
	}

	.img_box{
		width: 58%;
		margin-bottom: 20px;
		@include media($mobileSize){
			width: 60%;
			max-width: 260px;
			margin-bottom: 10px;
		}
		img{
			width: 100%;
		}
	}

	.conpanyName_box {
		//color: $fontColorSub;
		font-weight: bold;
		//font-family: $fontFamilyMin;
		@include fontsize(28);
		@include media($mobileSize){
			@include fontsize(24);
			margin-bottom: 10px;
		}
		span {
			@include fontsize(18);
			vertical-align: text-top;
		}
	}
}

.footer_inner .right_box{
	width: 38%;
	float: right;
	margin-top: 15px;
	@include clearfix();

	@include media($mobileSize){
		width: 100%;
		float: none;
		text-align: center;
		margin-top: 5px;
	}

	.footer_tel{
		span{ 
			a {
			color: $keyColor;
			@include fontsize(23);
			font-weight: bold;
			line-height: 0;
			}
		}
		@include media($mobileSize){
			margin-bottom: 4px;
		}
	}
	
	.footer_hours{
		@include fontsize(15);
		margin-bottom: 6px;
	}
	
	.footer_address{
		@include fontsize(14);
	}
	
	small{
		display: block;
		text-align: left;
		@include fontsize(11);
		//margin-top: 4px;
		
		@include media($mobileSize){
			margin-top: 8px;
			text-align: center;
		}
	}
	.cta_btn{
		margin-bottom: 6px;
		a{
			display: block;
			background-color: $keyColor;
			@include fontsize(14);
			text-align: center;
			color: $fontColorSub;
			width: 100%;
			padding: 6px 0;
		}
	}
}
		
	

