/**************************************

recruit.scss

***************************************/
.recruit{
	/*-------------------------------
	アイキャッチ
	-------------------------------*/
	.recruit__title{
		position: relative;
    	width: 100%;
		max-width: 1280px;
		min-width: 1000px;
    	height: 520px;
		margin: 0 auto 60px;
		@include media($mobileSize){
			min-width: inherit;
			height: 300px;
			margin-bottom: 30px;
		}
	}
	.recruit__bgImg{
		width: 100%;
		height: 100%;
		background: url(../img/recruit/bg_01.jpg) left bottom no-repeat;
		background-size: cover;
		@include media($mobileSize){
		}
	}
	.recruit__titleHeading{
		position: absolute;
    	top: 60%;
    	left: 12.5%;
		width: 255px;
		margin-left: auto;
		text-align: right;
		img{
			width: 100%;
		}
		@include media($tabletSize){
			left: 2.08%;
		}
		@include media($mobileSize){
			left: 6.75%;
			width: 36%;
		}
	}
	
	
	/*-------------------------------
	メッセージ
	-------------------------------*/
	.recruit__message{
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	
	.recruit__messageHeading{
		width: 18.75%;
		height: auto;
		margin-bottom: 4px;
		@include media($mobileSize){
			width: 30%;
		}
		img{
			width: 100%;
			height: auto;
		}
	}
	
	.recruit__messageFrom{
		margin-bottom: 30px;
		@include media($mobileSize){
			margin-bottom: 20px;
		}
	}
	
	.recruit__messageDiscription{
		display: flex;
		justify-content: space-between;
		line-height: 1.6;
		margin-bottom: 80px;
		@include media($mobileSize){
			display: inline-block;
			width: 100%;
			margin-bottom: 30px;
		}
	}
	
	.recruit__messageTxt{
		width: 50%;
		font-weight: bold;
		span {
			color: $keyColor;
			font-weight: bold;
		}
		@include media($mobileSize){
			width: 100%;
			margin-bottom: 20px;
		}
	}
	.recruit__messagePic{
		width: 45%;
		p {
			font-weight: bold;
			margin-top: 5px;
			text-align: center;
		}
		@include media($mobileSize){
			width: 100%;
		}
	}
	
	/*-------------------------------
	採用のご応募はこちら　上
	-------------------------------*/
	.recruit__engagementUpper{
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	.recruit__engagementUpperFrame{
		position: relative;
		border: 1px solid $borderColor;
		//padding: 25px 10px;
	}
	.recruit__engagementUpperFig{
		width: 338px;
		img{
			width: 100%;
		}
		@include media($mobileSize){
			width: 100%;
			margin-bottom: 10px;
		}
	}
	.recruit__engagementUpperCont{
		position: absolute;
		top: 50%;
		right: 55px;
		width: 525px;
		@include translateY(-50%);
		@include media($mobileSize){
			position: static;
			width: 100%;
			@include translateY(0);
		}
		
		.cont__upper{
			display: table;
			margin-bottom: 10px;
			@include media($mobileSize){
				display: block;
			}
			
			.cont__upperTxt{
				display: table-cell;
				width: 364px;
				padding-right: 8px;
				vertical-align: middle;
				img{
					width: 100%;
				}
				@include media($mobileSize){
					display: block;
					width: 80%;
					margin: 0 auto 10px;
				}
			}
			.cont__upperBtn{
				display: table-cell;
				vertical-align: middle;
				width: 162px;
				height: 38px;
				line-height: 38px;
				text-align: center;
				a{
					display: block;
					width: 100%;
					color: $fontColorSub;
					background-color: $keyColor;
				}
				
				@include media($mobileSize){
					display: block;
					margin: 0 auto 10px;
				}
			}
		}
		.cont__lower{
			vertical-align: middle;
			&Tel a{
				color: $keyColor;
				font-weight: bold;
			}
			@include media($mobileSize){
				margin-bottom: 10px;
				span{
					@include fontsize(22);
					display: block;
					text-align: center;
				}
			}
		}
	}
	
	
	/*-------------------------------
	福利厚生
	-------------------------------*/
	.recruit__welfare{
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	
	.recruit__welfareUpper{
		margin-bottom: 20px;
	}
	
	.welfare__fig{
		@include clearfix();
		margin-bottom: 15px;
		
		&--col2{
			.welfare__figItem{
				width: 50%;
			}
		}
		
		&--col3{
			.welfare__figItem{
				width: 33.33%;
			}
		}
	}
	.welfare__figItem{
		float: left;
		img{
			width: 100%;
		}
	}
	
	.recruit__welfareTxt{
		text-align: center;
		font-weight: bold;
		line-height: 1.6;
		@include media($mobileSize){
			text-align: left;
		}
	}
	
	
	/*-------------------------------
	募集要項
	-------------------------------*/
	.recruit__points{
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	
	/*-------------------------------
	募集要項 項目
	-------------------------------*/
	.points__frame{
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	.points__infoBox{
		@include clearfix();
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	.points__infoHeading{
		width: 15.315%;
		float: left;
		@include fontsize(23);
		color: $keyColor;
		font-weight: bold;
		@include media($mobileSize){
			width: 100%;
			float: none;
			@include fontsize(20);
			margin-bottom: 10px;
		}
	}
	.points__info{
		width: 83.413%;
		float: right;
		line-height: 1.8;
		@include media($mobileSize){
			width: 100%;
			float: none;
			line-height: normal;
		}
		.points__over60 {
			margin-bottom: 30px;
			border: 1px solid #333;
			text-align: center;
			padding: 10px 0;
			//color: $fontColorHeading;
			font-weight: bold;
			@include fontsize(16);
			@include media($mobileSize){
			@include fontsize(14);
		  }
		    @include media($mobileSize_s){
			@include fontsize(12);
		  }
		}
	}
	.points__infoInner{
		@include clearfix();
		.points__position {
		@include fontsize(18);
		font-weight: bold;
		margin-bottom: 10px;
		@include media($mobileSize){
		@include fontsize(16);
		  }
		}
		dt{
			width: wCon(160,800);
			float: left;
			font-weight: bold;
			@include media($mobileSize){
				width: 100%;
				float: none;
				margin-top: 10px;
				margin-left: 15px;
			}
		}
		dd{
			width: wCon(630,800);
			float: left;
			margin-left: 10px;
			@include media($mobileSize){
				width: 90%;
				float: none;
				margin-left: 30px;
			}
		}
		.indent{
			display: inline-block;
			padding-left: 1em;
		}
	}
	.points__how{
		@include clearfix();
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	.points__howHeading{
		width: 15.315%;
		float: left;
		@include fontsize(23);
		color: $keyColor;
		font-weight: bold;
		@include media($mobileSize){
			width: 100%;
			float: none;
			@include fontsize(20);
		}
	}
	.points__howTxt{
		width: 83.413%;
		float: right;
		line-height: 1.8;
		@include media($mobileSize){
			width: 100%;
			float: none;
			line-height: normal;
		}
	}
	.points__howTxtInner{
		dd{
			width: 77.078%;
			float: left;
			margin-left: 10px;
		}
		@include media($mobileSize){
			dd{
				width: 90%;
				float: none;
				margin-top: 10px;
				margin-left: 30px;
			}
		}
	}
	
	/*-------------------------------
	採用のご応募はこちら 下
	-------------------------------*/
	.engagementBottom{
		margin-bottom: 60px;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	
	.engagementBottom__copy{
		@include fontsize(21);
		text-align: center;
		font-weight: bold;
		color: $keyColor;
		margin-bottom: 70px;
		line-height: 1.6;
		@include media($mobileSize){
			margin-bottom: 40px;
		}
	}
	
	.engagementBottom__frame{
		text-align: center;
	}
	
	.engagementBottom__heading{
		width: 715px;
		margin: 0 auto 40px;
		@include clearfix();
		border: 1px solid #CCCCCC;
		img{
			width: 100%;
		}
		@include media($mobileSize){
			width: 76%;
			margin: 0 auto 20px;
		}
	}
	.engagementBottom__headingFig{
		width: 338px;
		float: left;
		@include media($mobileSize){
			width: 100%;
			float: none;
		}
	}
	.engagementBottom__headingTxt{
		//width: 355px;
		float: left;
		margin: 20px 20px 0 20px;
		//padding-top: 35px;
		p {
			@include fontsize(30);
			font-weight: bold;			
		}
		@include media($mobileSize){
			width: 100%;
			float: none;
			margin: 0 0 10px;
		}
		p.cont__upperBtn {
			@include fontsize(18);
    		vertical-align: middle;
    		width: 162px;
    		height: 38px;
    		line-height: 38px;
    		text-align: center;			
			a {
				display: block;
    			width: 100%;
    			color: #ffffff;
    			background-color: #d34f4f;
			}
			@include media($mobileSize){
				margin: 0 auto;
			}
		}
	}
	
	.engagementBottom__discription{
		font-weight: bold;
		line-height: 1.6;
		margin-bottom: 40px;
		@include media($mobileSize){
			margin-bottom: 20px;
		}
	}
	.engagementBottom__copy {
		@include fontsize(21);
		color: $keyColor;
	}
	.engagementBottom__txt{
		@include fontsize(19);
		line-height: 1.6;
		.tel a{
			@include fontsize(22);
			font-weight: bold;
			color: $keyColor;
		}
	}
	
	/*-------------------------------
	コンタクトフォーム input
	-------------------------------*/
	.recruit_form{
		@include media($mobileSize){
			width: 90%;
			text-align: left;
			line-height: 1.4;
		}
	}
	
	/*.recruit_form .tel{
		margin-bottom:77px;
	}
	
	.recruit_form .tel span{
		font-weight:bold;
		font-size:19px;
	}*/
	
	.recruit_form table{
		border-collapse:separate;
		border-spacing:0 14px;
		@include media($mobileSize){
			width: 100%;
	    border-spacing: 0;
		}
	}
	
	.recruit_form tr{
		padding-bottom:10px;
		@include media($mobileSize){
			width:100%;
    	padding-bottom: 10px;	
		}
	}
	
	.recruit_form th{
		font-weight: bold;
		text-align:right;
		padding-right:10px !important;
		width: 150px;
		@include media($mobileSize){
			display:block;
			text-align:left;
			padding-right:5% !important;
			margin-top:20px;
		}
	}
	
	.recruit_form td{
		@include media($mobileSize){
			float:left;
		}
	}
	
	.recruit_form td.bar{
		/*width:55.208%;*/
		width: 60.22%;
		padding-right:10px;
		box-sizing:border-box;
		@include media($mobileSize){
			width:100%;
			padding-right: 10px;
			box-sizing: border-box;
			margin: 2px 0;
		}
	}
	
	.recruit_form .inner{
		width:100%;
		box-sizing:border-box;
		height:36px;
	}
	
	.recruit_form .ques_tit{
		vertical-align:top;
	}
	
	.recruit_form .question{
		height:250px;
		@include media($mobileSize){
			height: 180px;
		}
	}

	.txt_center{
		width:370px;
		margin: 70px auto 0;
		@include media($mobileSize){
			width: 250px;
			margin: 0 auto;
		}
	}
	
	.inner_container{
		margin-top:161px;
		@include media($mobileSize){
			margin-top: 50px;
		}
	}
	
	.submit_btn{
		width: 100%;
		color: $fontColorSub;
		background-color: $keyColor;
		border: none;
		padding: 8px 0;
		@include fontsize(23);
		font-weight: bold;
	}

	#contact_policy {
		width: 100%;
		max-width: 540px;
		height: 180px;
		font-size: 12px;
		overflow-y: auto;
		padding: 10px;
		border: 1px solid #acacac;
		box-sizing:border-box;
		margin-bottom:10px;
	}
	
	.all_error{
		color:#ea1c24;
		margin-bottom:20px;
	}
	
	.mail_add{
		margin-top:20px;
	}
	
	.return{
		margin-top:50px;
		margin-bottom:20px;
	}
	
	.recruit_form{
		.to_top a{
			width:100%;
			margin:0 auto;
			display: block;
			color: $fontColorSub;
			background-color: $keyColor;
			padding: 10px 10px;
			text-align: center;
			@include media($mobileSize){
			}
		}	
	}
	
	
	
	.alpha th{
		font-weight:bold;
	}

	.return_btn{
		margin:0 auto;
		color: $keyColor;
		background-color: $bgColorMain;
		border:$keyColor solid 1px;
		padding:6px 0;
		width:180px;
		font-size:18px;
		font-weight:bold;
		margin-top:30px;
	}
	
	.submit_btn2{
		color:$fontColorSub;
		background-color:$keyColor;
		border:none;
		padding:6px 0;
		width:300px;
		font-size:23px;
		font-weight:bold;
		margin-top:30px;
		@include media($mobileSize){
			width: 230px;
		}
	}

	/*-------------------------------
	コンタクトフォーム 汎用
	-------------------------------*/
	.font_red{
		color:#ea1c24;
	}
	.font_gray{
		color:#6c6d6e;
	}
	.font_12{
		font-size:12px;
	}
	.note{
		display: inline-block;
		padding-top: 4px;
	}
	.example{
		margin-top: 4px;
	}
	.error{
		margin-top: 2px;
	}
	
	/*-------------------------------
	コンタクトフォーム confirm
	-------------------------------*/
	.recruit_form{
		h2{
			font-weight:bold;
			font-size:22px;
			/*margin-top:96px;*/
			margin-bottom:25px;
			color: $keyColor;
			@include media($mobileSize){
				font-size:20px;
				/*margin-top:40px;*/
				margin-bottom:15px;
			}
		}
		p{
			line-height: 1.75;
		}
		.tellink1{
			color: $keyColor;
		}
	}

		.section_confirm {
		.section_confirm__inner {
			width: 100%;
			max-width: 960px;
			margin: 0 auto;
			/*padding: 60px 40px;*/

			@include media($mobileSize){
				width: 100%;
				/*padding: 60px 20px;*/
			}
			@include media($mobileSize){
				/*padding: 40px 10px;*/
			}

			h2 {
				font-size: 22px;
				font-weight: bold;
				color: $keyColor;
			}

			h2 + p {
				margin-top: 30px;
				line-height: 1.75;
			}

			table {
				width: 100%;
				max-width: 600px;
				margin: 20px auto;

				@include media($mobileSize){
					display: block;
					max-width: 100%;
				}
				th {
					width: 160px;
					padding: 10px 10px 0;
					vertical-align: top;
					font-weight: bold;
					text-align: right;

					@include media($mobileSize){
						display: block;
						width: 100%;
						padding: 0;
						text-align: left;
					}
				}
				td {
					padding: 10px 5px;

					@include media($mobileSize){
						display: block;
						padding: 2px 0;
					}
				}
			}

			.box_btn {
				display: table;
				margin-top: 60px;
				width: 100%;

				@include media($mobileSize){
					display: block;
				}

				form {
					display: table-cell;
					width: 50%;
					padding: 0 20px;
					text-align: center;

					@include media($mobileSize){
						display: block;
						width: 100%;
						margin-top: 40px;
					}

					.btn {
						background-color: $keyColor;
						border: 1px solid $keyColor;
						width: 100%;
						max-width: 370px;
						padding: 8px 0 4px;
						font-size: 23px;
						font-weight: bold;
						line-height: 1;
						color: $bgColorMain;
						cursor: pointer;
						/*@include transition();*/

						&:hover {
							background-color: $bgColorMain;
							color: $keyColor;
						}
					}

					.back_btn {
						background-color: $bgColorMain;
						color: $keyColor;

						&:hover {
							background-color: $keyColor;
							color: $bgColorMain;
						}
					}
				}
			}
		}
	}
	
	
	
	/*-------------------------------
	コンタクトフォーム finish,error
	-------------------------------*/
	.section {
		a.arrow {
			display: inline-block;
			background-color: $keyColor;
			border: 1px solid $keyColor;
			width: 100%;
			max-width: 370px;
			padding: 8px 0 4px;
			font-size: 23px;
			font-weight: bold;
			line-height: 1;
			color: $bgColorMain;
			cursor: pointer;
			text-align: center;
			margin-top: 40px;

			&:hover {
				background-color: $bgColorMain;
				color: $keyColor;
			}
		}
	}
}