/**************************************

contact.scss

***************************************/
.contact{
	
	/*お問い合わせ
		-----------------------*/
	.section_contact{
		margin-top: 75px;
		.info__box{
			padding: 0 40px;
			@include media($mobileSize){
				padding: 0;
			}
		}
		h2 {
			@include fontsize(22);
			font-weight: bold;
			color: $keyColor;
		}
		.txt01{
			margin-top: 20px;
			line-height: 1.75;
		}
		.txt02{
			@include fontsize(17);
			margin-top: 20px;
			@include media($mobileSize){
				line-height: 1.75;
				text-align: center;
			}
			span a{
				@include fontsize(19);
				font-weight: bold;
				color: $keyColor;
			}
		}
	}
	
	
	/*コンタクトフォーム
		-------------------------------*/
	.recruit_form{
		@include media($mobileSize){
			width: 90%;
			text-align: left;
			line-height: 1.4;
		}
	}	
	
	.recruit_form table{
		border-collapse:separate;
		border-spacing:0 14px;
		@include media($mobileSize){
			width: 100%;
	    border-spacing: 0;
		}
	}
	
	.recruit_form tr{
		padding-bottom:10px;
		@include media($mobileSize){
			width:100%;
    		padding-bottom: 10px;	
		}
	}
	
	.recruit_form th{
		font-weight: bold;
		text-align:right;
		padding-right:10px !important;
		@include media($mobileSize){
			display:block;
			text-align:left;
			padding-right:5% !important;
			margin-top:20px;
		}
	}
	
	.recruit_form td{
		@include media($mobileSize){
			float:left;
			&.btn {
				width: 100%;
				.txt_center {
					margin: 0 auto;
					text-align: center;
				}
			}
		}
	}
	
	.recruit_form td.bar{
		/*width:55.208%;*/
		width: 60.22%;
		padding-right:10px;
		box-sizing:border-box;
		@include media($mobileSize){
			width:100%;
			padding-right: 10px;
			box-sizing: border-box;
			margin: 2px 0;
		}
		textarea {
			resize: vertical;
			width: 539px;
			@include media($mobileSize){
				width: 100%;
			}
		}
	}
	
	.recruit_form .inner{
		width:100%;
		box-sizing:border-box;
		height:36px;
		border: 1px solid #CCC;
	}
	
	.recruit_form .ques_tit{
		vertical-align:top;
	}
	
	.recruit_form .question{
		height:250px;
		@include media($mobileSize){
			height: 180px;
		}
	}

	.txt_center{
		width:370px;
		margin: 70px auto 0;
		@include media($mobileSize){
			width: 250px;
			margin: 0 auto;
		}
	}
	
	.inner_container{
		margin-top:161px;
		@include media($mobileSize){
			margin-top: 50px;
		}
	}
	
	.submit_btn{
		width: 100%;
		color: $fontColorSub;
		background-color: $keyColor;
		border: none;
		padding: 8px 0;
		@include fontsize(23);
		font-weight: bold;
	}

	#contact_policy {
		width: 100%;
		max-width: 540px;
		height: 180px;
		font-size: 12px;
		overflow-y: auto;
		padding: 10px;
		border: 1px solid #acacac;
		box-sizing:border-box;
		margin-bottom:10px;
	}
	
	.all_error{
		color:#ea1c24;
		margin-bottom:20px;
	}
	
	.mail_add{
		margin-top:20px;
	}
	
	.return{
		margin-top:50px;
		margin-bottom:20px;
	}
	
	.to_top a{
		width:15%;
		margin:0 auto;
		display: block;
		color: $fontColorSub;
		background-color: $keyColor;
		padding: 10px 10px;
		@include media($mobileSize){
			width: 50%;
		}
	}
	
	.alpha th{
		font-weight:bold;
	}

	.return_btn{
		margin:0 auto;
		color: $keyColor;
		background-color: $bgColorMain;
		border:$keyColor solid 1px;
		padding:6px 0;
		width:180px;
		font-size:18px;
		font-weight:bold;
		margin-top:30px;
	}
	
	.submit_btn2{
		color:$fontColorSub;
		background-color:$keyColor;
		border:none;
		padding:6px 0;
		width:300px;
		font-size:23px;
		font-weight:bold;
		margin-top:30px;
		@include media($mobileSize){
			width: 230px;
		}
	}
	
	// コンタクトフォーム（確認）
	//-----------------------------/
	.section_confirm {
		.section_confirm__inner {
			width: 100%;
			max-width: 960px;
			margin: 0 auto;
			padding: 60px 40px;

			@include media($mobileSize){
				width: 100%;
				padding: 60px 20px;
			}
			@include media($mobileSize){
				padding: 40px 10px;
			}

			h2 {
				font-size: 22px;
				font-weight: bold;
				color: $keyColor;
			}

			h2 + p {
				margin-top: 30px;
				line-height: 1.75;
			}

			table {
				width: 100%;
				max-width: 600px;
				margin: 20px auto;

				@include media($mobileSize){
					display: block;
					max-width: 100%;
				}
				th {
					width: 160px;
					padding: 10px 10px 0;
					vertical-align: top;
					font-weight: bold;
					text-align: right;

					@include media($mobileSize){
						display: block;
						width: 100%;
						padding: 0;
						text-align: left;
					}
				}
				td {
					padding: 10px 5px;
					@include media($mobileSize){
						display: block;
						padding: 2px 0;
					}

				}
			}

			.box_btn {
				display: table;
				margin-top: 60px;
				width: 100%;

				@include media($mobileSize){
					display: block;
				}

				form {
					display: table-cell;
					width: 50%;
					padding: 0 20px;
					text-align: center;

					@include media($mobileSize){
						display: block;
						width: 100%;
						margin-top: 40px;
					}

					.btn {
						background-color: $keyColor;
						border: 1px solid $keyColor;
						width: 100%;
						max-width: 370px;
						padding: 8px 0 4px;
						font-size: 23px;
						font-weight: bold;
						line-height: 1;
						color: $bgColorMain;
						cursor: pointer;
						/*@include transition();*/

						&:hover {
							background-color: $bgColorMain;
							color: $keyColor;
						}
					}

					.back_btn {
						background-color: $bgColorMain;
						color: $keyColor;

						&:hover {
							background-color: $keyColor;
							color: $bgColorMain;
						}
					}
				}
			}
		}
	}
	
	// コンタクトフォーム（完了）
	//-----------------------------/
	.section_finish {
		.section_finish__inner {
			width: 100%;
			max-width: 960px;
			margin: 0 auto;
			padding: 60px 40px;

			@include media($mobileSize){
				width: 100%;
				padding: 60px 20px;
			}
			@include media($mobileSize){
				padding: 40px 10px;
			}

			h2 {
				font-size: 22px;
				font-weight: bold;
				color: $keyColor;
				margin-bottom: 30px;
			}
			p {
				line-height: 1.75;
			}
			.tel {
				margin-top: 10px;
				font-weight: bold;
				color: $keyColor;
			}

			.box_btn {
				width: 100%;
				margin-top: 40px;
				text-align: center;

				ul {
					li {
						margin-top: 20px;
					}

					.btn {
						a {
							display: inline-block;
							background-color: $keyColor;
							border: 1px solid $keyColor;
							width: 100%;
							max-width: 370px;
							padding: 8px 0 4px;
							font-size: 23px;
							font-weight: bold;
							line-height: 1;
							color: $bgColorMain;
							cursor: pointer;
							/*@include transition();*/

							&:hover {
								background-color: $bgColorMain;
								color: $keyColor;
							}
						}
					}
				}
			}
		}
	}
	
	// コンタクトフォーム（エラー）
	//-----------------------------/
	.section_error {
		.section_error__inner {
			width: 100%;
			max-width: 960px;
			margin: 0 auto;
			padding: 60px 40px;

			@include media($mobileSize){
				width: 100%;
				padding: 60px 20px;
			}
			@include media($mobileSize){
				padding: 40px 10px;
			}

			h2 {
				font-size: 22px;
				font-weight: bold;
				color: $keyColor;
			}
			h2 + p {
				margin-top: 30px;
				line-height: 1.75;
			}
			.tel {
				margin-top: 10px;
				font-weight: bold;
				color: $keyColor;
			}
			.box_error {
				margin-top: 30px;
			}

			.box_btn {
				width: 100%;
				margin-top: 40px;
				text-align: center;

				.btn {
					a {
						display: inline-block;
						background-color: $keyColor;
						border: 1px solid $keyColor;
						width: 100%;
						max-width: 370px;
						padding: 8px 0 4px;
						font-size: 23px;
						font-weight: bold;
						line-height: 1;
						color: $bgColorMain;
						cursor: pointer;
						/*@include transition();*/

						&:hover {
							background-color: $bgColorMain;
							color: $keyColor;
						}
					}
				}
			}
		}
	}

	/* === コンタクトフォーム _汎用===== */
	.font_red{
		color:#ea1c24;
	}
	.font_gray{
		color:#6c6d6e;
	}
	.font_12{
		font-size:12px;
	}
	.note{
		display: inline-block;
		padding-top: 4px;
	}
	.example{
		@include fontsize(13);
		color: $fontColorHeading2;
		margin-top: 4px;
	}
	.error{
		margin-top: 2px;
	}

}