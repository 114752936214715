/**************************************

service.scss

***************************************/
.service{
	.main{
		margin-bottom: 120px;
		
		@include media($mobileSize){
			margin-bottom: 30px;
		}
	}
	/*-------------------------------
	アイキャッチ
	-------------------------------*/
	.service__title{
		position: relative;
    	width: 100%;
		max-width: 1280px;
		min-width: 1000px;
    	height: 520px;
		margin: 0 auto 50px;
		@include media($mobileSize){
			min-width: inherit;
			height: 300px;
			margin-bottom: 25px;
		}
	}
	
	.service__bgImg{
		width: 100%;
		height: 100%;
		background: url(../img/service/bg_01.jpg) center bottom no-repeat;
		background-size: cover;
	}
	
	.service__Heading{
		position: absolute;
		bottom: 10%;
		right: 12.5%;
		width: 255px;
		img{
			width: 100%;
		}
		@include media($mobileSize){
			right: 6.75%;
			width: 36%;
		}
	}

	/*-------------------------------
	事業内容
	-------------------------------*/
	h3.hed_txt{
		@include fontsize(36);
		font-weight: bold;
		color: $keyColor;
		line-height: 1.75;
		text-align: center;
		margin-bottom: 50px;
		@include media($mobileSize){
			width: 87.5%;
			@include fontsize(22);
			margin: 0 auto 25px;
			line-height: 1.5;
			text-align: left;
		}
	}
	.box_odd{
		width: 100%;
		@include clearfix();
		&:last-child {
			.left_box {
				@include media($mobileSize){
					margin: 10px 0 0;
				}
			}
		}
		.box{
			&:last-of-type{
				margin-bottom: 50px;
			}
		}
		.right_box{
			float: right;
			width: 50%;
			img{
				width: 100%;
				vertical-align: top;
			}
			@include media($mobileSize){
				float: none;
				width: 87.5%;
				margin: 0 auto;
			}
		}
		.left_box{
			float: left;
			width: 50%;
			height: 0;
			padding-top: 31.25%;
			position: relative;
			@include media($tabletSize){
				height: 312px;
			}
			@include media($mobileSize){
				float: none;
				width: 100%;
				height: 160px;
				margin: 10px 0;
			}
			.box_inner{
				width: 43.75%;
				height: auto;
				position: absolute;
				top:50%;
				@include translateY(-50%);
				right: 5%;
				@include media($tabletSize){
					width: 70%;
				}
				@include media($mobileSize){
					width: 87.5%;
				}
			}
			h4{
				@include fontsize(22);
				font-weight: bold;
				color: $keyColor;
				line-height: 1.45;
				margin-bottom: 3.692%;
				@include media($mobileSize){
					@include fontsize(20);
				}
			}
			p{
				@include fontsize(16);
				line-height: 1.51;
				@include media($mobileSize){
					@include fontsize(14);
				}
			}
		}
	}
	.box_even{
		width: 100%;
		@include clearfix();
		.box{
			&:last-of-type{
				margin-bottom: 50px;
			}
		}
		.left_box{
			float: left;
			width: 50%;
			img{
				width: 100%;
				vertical-align: top;
			}
			@include media($mobileSize){
				float: none;
				width: 87.5%;
				margin: 0 auto;
			}
		}
		.right_box{
			float: right;
			width: 50%;
			height: 0;
			padding-top: 31.25%;
			position: relative;
			@include media($tabletSize){
				height: 312px;
			}
			@include media($mobileSize){
				float: none;
				width: 100%;
				height: 160px;
				margin: 10px 0;
			}
			.box_inner{
				width: 43.75%;
				height: auto;
				position: absolute;
				top:50%;
				@include translateY(-50%);
				left: 5%;
				@include media($tabletSize){
					width: 70%;
				}
				@include media($mobileSize){
					width: 87.5%;
				}
			}
			h4{
				@include fontsize(22);
				font-weight: bold;
				color: $keyColor;
				line-height: 1.45;
				margin-bottom: 3.692%;
				@include media($mobileSize){
					@include fontsize(20);
				}
			}
			p{
				@include fontsize(16);
				line-height: 1.51;
				@include media($mobileSize){
					@include fontsize(14);
				}
			}
		}
	}
	/*-------------------------------
	動画
	-------------------------------*/
	.video{
		width: 87.5%;
		max-width: 1280px;
    	margin: 0 auto;
		&__items{
			width: 100%;
  			display:-webkit-box;
  			display:-ms-flexbox;
    		display: flex;
  			-ms-flex-wrap: wrap;
    		flex-wrap: wrap;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
    		justify-content: space-between;
    		margin-top: 40px;
    		@include media($mobileSize){
    			margin-top: 0;
    		}
    		@include media($mobileSize){

    		}
    		video{
    			//max-width: 410px;
    			width: 32%;
    			height: auto;
    			@include media($mobileSize){
    				width: 100%;
    				//display: block;
    				height: 30%;
    				max-height: 640px;
    				margin-bottom: 20px;
    			}
    			&:last-child{
    				margin-bottom: 0;
    			}	
    		}
    	}
	}
	/*-------------------------------
	運送会社をお探しならこちら
	-------------------------------*/
	.contact__box{
		@include clearfix();
		width: 68.75%;
		max-width: 860px;
		padding: 12px 18px;
		border: #cccccc 1px solid;
		margin: 60px auto 100px;
		@include media($tabletSize){
			width: 90%;
	    	margin: 40px auto 60px;
	    	box-sizing: border-box;
		}
		@include media($mobileSize){
			margin-bottom: 30px;
		}
	}
	.contact__boxHeading{
		width: 44%;
		float: left;
		margin-top: 20px;
		padding-right: 3.745%;
		box-sizing: border-box;
		font-weight: bold;
		@include fontsize(23);
		@include media($tabletSize){
		    max-height: 300px;
			width: 100%;
			float: none; 
			@include fontsize(20);
			margin-top: 0;
		}
	}
	.contact__boxInfo{
		width: 56%;
		float: right;
		@include media($tabletSize){
			width: 100%;
			float: none; 
		}
	}
	.contact__boxTell{
		@include fontsize(23);
		span {
			a {
				color: $keyColor;
				font-weight: bold;
			}
		}
	}
	.contact__boxBtn{
		text-align: center;
		margin: 10px 0;
		a{
			display: block;
			padding: 8px 0;
			color: $fontColorSub;
	    	background-color: $keyColor;
		}
	}
	/*-------------------------------
	事業案内column2
	-------------------------------*/
	.service__subContents{
		margin-bottom: 80px;
		@include media($tabletSize){
			margin-botoom: 60px;
		}
		@include media($mobileSize){
			margin-bottom: 30px;
		}
	}
	.service__subContentsHeading{
		width: 100%;
		text-align: center;
		margin: 0px auto 70px;
		@include fontsize(23);
		font-weight: bold;
		color: $keyColor;	
		@include media($tabletSize){
			@include fontsize(20);
			margin-bottom: 30px;
		}
		@include media($mobileSize){
			//@include fontsize(18);
			margin-bottom: 20px;
		}
	}
	.service__subContentsInner{
		@include clearfix();
		width: 100%;
		margin: 0 auto;
	}
	.service__subContentsItem{
		&:nth-of-type(1){
			width: 47.954%;
			float: left;
			@include media($mobileSize){
			width: 100%;
			max-width: 350px;
			float: none;
			margin: 0 auto 20px;
			}
		}
		&:nth-of-type(2){
			width: 47.954%;
			float: right;
			@include media($mobileSize){
			width: 100%;
			max-width: 350px;
			float: none;
			margin: 0 auto;
			}
		}
	}
	.service__subContentsItemFigure{
		width: 100%;
		height: auto;
		margin-bottom: 20px;
		img{
			width: 100%;
			/*max-width: 350px;*/
			height: auto;
		}
	}
	.service__subContentsItemHeading{
		margin-bottom: 20px;
		@include fontsize(23);
		font-weight: bold;
		color: $keyColor;
		@include media($mobileSize){
			@include fontsize(18);
			margin-bottom: 5px;
		}
	}
	.service__subContentsItemTxt{
		@include fontsize(14);
	}
	.service__subContentsItemDiscription_ir{
		clear: both;
	}
	.service__subContentsItemHeading_ir{
		text-align: center;
		@include fontsize(23);
		font-weight: bold;
		margin-bottom: 20px;
		@include media($mobileSize){
			@include fontsize(18);
			margin-bottom: 10px;
		}
	}
	.service__subContentsItemTxt_ir{
		text-align: center;
		margin: 0 auto;
		@include media($mobileSize){
			text-align: left;
		}
	}
}







