/**************************************

base.scss

***************************************/
/* ---------------------------------------------------------
スタイルリセット
----------------------------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-style:normal;
    font-weight: normal;
}

h1,h2,h3,h4,h5,h6,td,th,p,ul,li,dt,dd {
    font-size: 1em;
}

ul li{
  list-style: none;
}

article, header, footer, aside, figure, figcaption, nav, section { 
  display:block;
}

body {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img{
    vertical-align: bottom;
}


/* ---------------------------------------------------------
変数
----------------------------------------------------------*/
/*break point
-----------------------*/
$pcSize: 1280px;
$tabletSize: 1000px;
$mobileSize: 640px;
$mobileSize_s: 400px;

/*color
-----------------------*/
$bgColorMain: #ffffff;
$bgColorSub: #8d9eb5;
$fontColorMain: #000000;
$fontColorSub: #ffffff;
$fontColor03: #CCCCCC;
//$fontColorHeading: #0085d0;
$fontColorHeading2: #4d4d4d;
//$headerBaseColor: rgba(5,63,153,0.8);
//$headerSubColor: rgba(231,239,252,0.8);
//$footerBaseColor: #efefef;
$borderColor: #cccccc;
$keyColor: #d34f4f;

/*font family
-----------------------*/
$fontFamilyBase: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
$fontFamilyHeading: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
//$fontFamilyMin: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

/* ---------------------------------------------------------
mixin
----------------------------------------------------------*/
/*メディアクエリ
-----------------------*/
@mixin media($breakPoint){
    @media screen and (max-width: $breakPoint) {
        @content;
    }
}
/*clearfix
-----------------------*/
@mixin clearfix(){
    &::before,
    &::after{
        content: '';
        display: table;
    }
    &::after{
        clear: both;
    }
}

/*widthの横幅計算
-----------------------*/
@function wCon($width,$parentWidth:$columnWidth) {
    @return $width / $parentWidth * 100%;
}

/*font-size
-----------------------*/
@mixin fontsize($size: 16, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}


/*transform
-----------------------*/
/*-- translate--*/
@mixin translate($translate-x,$translate-y) {
	-webkit-transform: translate($translate-x,$translate-y);
  -moz-transform: translate($translate-x,$translate-y);
	-ms-transform: translate($translate-x,$translate-y);
	transform: translate($translate-x,$translate-y);
}

/*-- translateY--*/
@mixin translateY($translateY) {
	-webkit-transform: translateY($translateY);
  -moz-transform: translateY($translateY);
	-ms-transform: translateY($translateY);
	transform: translateY($translateY);
}

/*-- translateX--*/
@mixin translateX($translateX) {
  -webkit-transform: translateX($translateX);
  -moz-transform: translateX($translateX);
  -ms-transform: translateX($translateX);
  transform: translateX($translateX);
}

/*opacity
-----------------------*/
@mixin opa(){
    opacity: 0.8;
    transition: opacity 0.4s;
}

/* ---------------------------------------------------------
初期設定
----------------------------------------------------------*/
html {
    color: $fontColorMain;
    background-color: $bgColorMain;
    font-size: 16px;
    /*letter-spacing: 1px;*/
    @include media($mobileSize){
        font-size: 14px;
    }
}
body {
	font-family: $fontFamilyBase;
	line-height: 1.7;
    // height: 100%;
}
a{
	text-decoration:none;
	color: $fontColorMain;
}
img{
    max-width: 100%;
    height: auto;
}


