/**************************************

top.scss

***************************************/
.top{
	/*-------------------------------
	アイキャッチ
	-------------------------------*/
	.top__title{
		position: relative;
		width: 100%;
		max-width: 1280px;
		min-width: 1000px;
		height: 520px;
		margin: 0 auto 60px;
		@include media($mobileSize){
			min-width: inherit;
			height: 300px;
			margin-bottom: 30px;
		}
	}
	.top__bgImg{
		width: 100%;
		height: 100%;
		background: url(../img/top/bg_01.jpg) center bottom no-repeat;
		background-size: cover;
		@include media($mobileSize){
			background: url(../img/top/bg_01.jpg) center bottom no-repeat;
			background-size: cover;
		}
	}

	.top__titleHeading{
		position: absolute;
		top: calc(50% - 206px);
		left: calc(50% - 400px);
		max-width: 800px;
		width: 100%;
		//@include translateX(-50%);
		img{
			width: 100%;
		}
		@include media($mobileSize){
			top: calc(50% - 106px);
			left: calc(50% - 200px);
			max-width: 400px;
		}
		@include media($mobileSize_s){
			top: calc(50% - 83.5px);
			left: calc(50% - 155px);
			max-width: 310px;
			//width: 87.5%;
			//text-align: center;
			//top: 50%;			
			//@include translateY(-50%);
			img{
				width: 100%;
			}
		}
	}
	.top__under {
		position: absolute;
		top: 90%;
		left: calc(50% - 13px);
		@include media($mobileSize){
			max-width: 30px;
			top: 86%;
			left: calc(50% - 12px);
		}
	}
	.top__title .inner .title__txt{
		color: #fff;
		text-shadow: 1px -1px 3px #000;
		padding-left: 2%;
		position: absolute;
		bottom: 8%;
		@include fontsize(22);
		@include media($mobileSize){
			bottom: 10%;
			line-height: 1.2;
			@include fontsize(18);
			width: 87.5%;
			text-align: center;
		}
		@media screen and (max-width: 445px){
			.br_sp{
				display: none;
			}
		}
		@include media($mobileSize_s){
			bottom: 5%;
		}
	}

	/*-------------------------------
	インフォメーション
	-------------------------------*/
	.information__box{
		@include clearfix();
		line-height: 1.75;
		margin-bottom: 60px;
		.left__box{
			width: 40%;
			float: left;
			h3{
				@include fontsize(19);
				text-align: center;
				font-weight: bold;
				span {
					color: $bgColorSub;
					@include fontsize(17);
				}
			}
			@include media($mobileSize){
				width: 100%;
				float: none;
				margin-bottom: 20px;
				}
			}
		.right__box{
			width: 60%;
			float: left;
			@include clearfix();
			dl{
				@include clearfix();
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			dt{
				width: wCon(120,576);
				float: left;
				//margin-right: 4.545%;
				@include media($mobileSize){
					width: 100%;
				}
			}
			dd{
				width: wCon(456,576);
				float: left;
				@include media($mobileSize){
					width: 100%;
				}
				a{
				color: $keyColor;
				text-decoration:underline;
				}
				span.bb{
					font-weight: bold;
					font-size: 15px;
				}
				span.ss{
					font-size: 13px;
				}
			}
			.right__box--indent {
				text-indent: 37px;
				@include media($mobileSize){
					text-indent: 0px;
				}
			}
			@include media($mobileSize){
				width: 100%;
				float: none;
			}
		}
	}

	/*-------------------------------
	サービス事業
	-------------------------------*/
	.top__service{
		position: relative;
		width: 100%;
		max-width: 1280px;
		min-width: 1000px;
		height: 600px;
		margin: 0 auto 15px;
		@include media($mobileSize){
			min-width: inherit;
			height: 300px;
			margin-bottom: 20px;
		}
	}
	.service__bgImg{
		width: 100%;
		height: 100%;
		background: url(../img/top/bg_02.jpg) center bottom no-repeat;
		background-size: cover;
		@include media($mobileSize){
		}
	}

	.top__serviceHeading{
		width: 709px;
		position: absolute;
		top: 78%;
		left: 12.5%;
		img{
			width: 100%;
		}
		a {
			display: inline-block;
		}
		@include media($tabletSize){
			left: 3%;
		}
		@include media($mobileSize){
			width: 85%;
			top: 70%;
			left: 6.25%;
		}
	}
	.products__list{
		position: absolute;
		bottom: 15%;
		left: 38%;
		ul{
			width: 340px;
			height: 180px;
			line-height: 1.625;
			@include media($mobileSize_s){
				width: 100%;
				height: 135px;
				line-height: 1.6;
			}
			li{
				@include fontsize(16);
				@include media($mobileSize){
					@include fontsize(14);
				}
				@include media($mobileSize_s){
					@include fontsize(11);
				}
			}
		}
		@include media($mobileSize){
			display: none;
		}
	}

	/*＝＝＝Sp用＝＝＝*/
	.service__inner{
		@include media($mobileSize){
			padding-top: 5%;
		}
	}
	.sp_conts{
		@include clearfix();
		display: none;
		@include media($mobileSize){
			display: block;
			margin-top: 5%;
		}
		.left_box{
			display: block;
			float: left;
			width: 50%;
			.sp_pic{
				width: 100%;
				img{
					width: 100%;
				}
			}

		}
		.right_box{
			float: right;
			width: 50%;
			line-height: 1.5;
			li{
				@include media($mobileSize_s){
				@include fontsize(12)
				}
			}
		}
	}

	/*-------------------------------
	採用情報
	-------------------------------*/
	.top_recruit{
		position: relative;
    	width: 100%;
		max-width: 1280px;
		min-width: 1000px;
    	height: 600px;
		margin: 0 auto 15px;
		@include media($mobileSize){
			min-width: inherit;
			height: 300px;
		}
	}
	.top_recruit__bgImg{
		width: 100%;
		height: 100%;
		background: url(../img/top/bg_04.jpg) center bottom no-repeat;
		background-size: cover;
		@include media($mobileSize){
			background: url(../img/top/bg_04.jpg) left bottom no-repeat;
			background-size: cover;
		}
	}
	.top_recruit__titleHeading{
		width: 434px;
		position: absolute;
		top: 60%;
		left: 12.5%;
		img{
			width: 100%;
		}
		@include media($tabletSize){
			left: 2%;
		}
		@include media($mobileSize){
			width: 50%;
			left: 5%;
		}
	}

	/*-------------------------------
	会社概要
	-------------------------------*/
	/*---------------
	イメージ
	---------------*/
	.company__profile{
		position: relative;
    	width: 100%;
		max-width: 1280px;
		min-width: 1000px;
    	height: 600px;
		margin: 0 auto 50px;
		@include media($mobileSize){
			min-width: inherit;
			height: 300px;
			margin-bottom: 25px;
		}
	}
	.company__bgImg{
		width: 100%;
		height: 100%;
		background: url(../img/top/bg_03.jpg) center bottom no-repeat;
		background-size: cover;
		@include media($mobileSize){
		}
	}
	.company__titleHeading{
		width: 593px;
		position: absolute;
		top: 10%;
		left: 12.5%;
		img{
			width: 100%;
		}
		@include media($tabletSize){
			left: 2%;
		}
		@include media($mobileSize){
			width: 80%;
			left: 5%;
		}
	}

	/*---------------
	テキスト
	---------------*/
	.company__outer{
		.company__box {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include media($mobileSize){
	
			}
			.company__description {
				width: 100%;
				line-height: 1.75;
				margin-bottom: 50px;
				@include media($mobileSize){
					width: 100%;
					margin-bottom: 25px;
				}
				h4.company__innerHeading{
					color: $keyColor;
					@include fontsize(23);
					font-weight: bold;
					margin-bottom: 10px;
					text-indent: -12px;
				}
			}
		}
		.company__infoBox{
			@include clearfix();
			margin-bottom: 50px;
			.left__box{
				width: 55%;
				line-height: 1.75;
				float: left;
				@include media($mobileSize){
					width: 100%;
					float: none;
					margin-bottom: 30px;
				}
			}
			.right__box{
				width: 45%;
				float: right;
				iframe {
					width: 100%;
					height: 365px;
				}
				.pic {
					margin-bottom: 4%;
					img {
						max-width: 204px;
					}
				}
				@include media($mobileSize){
					width: 100%;
					float: none;
					text-align: center;
				}
			}
		}
		.company__infoInner{
			@include clearfix();
			dt{
				width: 20%;
				float: left;
				font-weight: bold;
				color: $keyColor;
				@include media($mobileSize){
					width: 100%;
					float: none;
					margin-top: 10px;
				}
			}
			dd{
				width: 80%;
				float: left;
				@include media($mobileSize){
					width: 100%;
					float: none;
				}
				&.link {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
	/*---------------
	動画
	---------------*/
	.video{
		width: 100%;
    	display: flex;
    	flex-wrap: wrap;
    	margin-top: 40px;
    	video{
    		width: 28%;
    		margin-right: 20px;
    	}
	}


}
